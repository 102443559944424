export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);
  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);
  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;
  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
  return "#"+RR+GG+BB;
};

export function setTransparency(hexColor, opacityPercent) {
  // Entferne das führende "#" und parse den hexadezimalen Farbwert zu einer Zahl
  const hexValue = hexColor.replace("#", "");
  const intValue = parseInt(hexValue, 16);

  // Extrahiere die Rot-, Grün- und Blauwerte aus der Zahl
  const red = (intValue >> 16) & 255;
  const green = (intValue >> 8) & 255;
  const blue = intValue & 255;

  // Berechne den Opazitätswert aus dem Prozentwert
  const opacity = opacityPercent / 100;

  // Setze die Opazität der Farbe und konvertiere sie zurück in einen hexadezimalen Farbwert
  const rgbaValue = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
  const rgbaColor =  hexToRgbA(hexColor, opacity);

  return rgbaColor;
}

// Hilfsfunktion, um den hexadezimalen Farbwert in einen rgba-Farbwert umzuwandeln
function hexToRgbA(hexValue, opacity) {
  const r = parseInt(hexValue.slice(1, 3), 16);
  const g = parseInt(hexValue.slice(3, 5), 16);
  const b = parseInt(hexValue.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}