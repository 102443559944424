import CarRenderer from '../../cars'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import styles from './home.module.css'
import MercedesSClass from '../../cars/mercedes-s-class'
import PorschePanamera from '../../cars/porsche-panamera'
import { useState } from 'react'
import Bugatti from '../../cars/bugatti'

const Home = () => {

  const [car, setCar] = useState("sclass")

  const renderCar = () => {
    switch (car) {
      case "sclass":
        return <MercedesSClass scale={1.7} />
      case "porsche":
        return <PorschePanamera scale={1.7} />
      default:
        return "Fehler"
    }
  }

  const renderCarName = () => {
    switch (car) {
      case "sclass":
        return "Mercedes Benz"
      case "porsche":
        return "Porsche"
      default:
        return "Fehler"
    }
  }

  const renderCarType = () => {
    switch (car) {
      case "sclass":
        return "S Klasse"
      case "porsche":
        return "Panamera GTS"
      default:
        return "Fehler"
    }
  }

  const Overlay = () => {
    return (
      <Box style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Box style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>WhiteStone</Box>
        <Box className={styles.carTitleWrapper} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Stack direction={'column'} spacing={3} textAlign={'center'}>
            <Stack direction={'column'}>
              <Typography className={styles.carTitle} variant={'h6'}>
                {renderCarName(car)}
              </Typography>
              <Typography variant={'body1'}>
                {renderCarType(car)}
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1}>
              <Button onClick={() => setCar("sclass")} disabled={car === "sclass"}>S Klasse</Button>
              <Button onClick={() => setCar("porsche")} disabled={car === "porsche"}>Porsche Panamera</Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Box className={styles.upperHomeWrapper}>
        <CarRenderer model={renderCar(car)} />
        <Overlay />
      </Box>
      <Box className={styles.lowerHomeWrapper}>
        <Container maxWidth={'lg'}>

        </Container>
      </Box>
    </Box>
  )
}

export default Home

