import { useLayoutEffect, useRef } from 'react'
import { applyProps, useFrame } from '@react-three/fiber'
import { AccumulativeShadows, RandomizedLight, useGLTF } from '@react-three/drei'

const Bugatti = (props) => {
  const { scene, nodes, materials } = useGLTF('/bugatti.glb')

  console.log(materials)

  const boxRef = useRef()

  useFrame(() => {
    if (!props.rotation) {
      boxRef.current.rotation.y = 0.7
    } else
      boxRef.current.rotation.y -= 0.001
  })

  //const { scene, nodes, materials } = useFBX('/Porsche-Panamera-GTS.fbx')
  useLayoutEffect(() => {
    Object.values(nodes).forEach((node) => node.isMesh && (node.receiveShadow = node.castShadow = true))

  }, [nodes, materials])
  return (
    <primitive ref={boxRef} object={scene} {...props} position={[0, -1.5, 0]} rotation={[0, 0, 0]}>
      <AccumulativeShadows temporal frames={100} alphaTest={0.9} scale={10}>
        <RandomizedLight amount={8} radius={10} ambient={0.5} position={[1, 5, -1]} />
      </AccumulativeShadows>
    </primitive>
  )
}


export default Bugatti
