import Home from './views/Home'
import { CssBaseline, ThemeProvider } from '@mui/material'
import theme from '../src/theme/theme';

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home/>
    </ThemeProvider>
  )
}

