// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_carTitleWrapper__LaFoP {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: 650px;
}
.home_upperHomeWrapper__FVZ77 {
    height: 100vh;
    background-color: white;
}
.home_lowerHomeWrapper__NV4h3 {
    height: 100vh;
    background-color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/Home/home.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".carTitleWrapper {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    padding-top: 650px;\n}\n.upperHomeWrapper {\n    height: 100vh;\n    background-color: white;\n}\n.lowerHomeWrapper {\n    height: 100vh;\n    background-color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carTitleWrapper": `home_carTitleWrapper__LaFoP`,
	"upperHomeWrapper": `home_upperHomeWrapper__FVZ77`,
	"lowerHomeWrapper": `home_lowerHomeWrapper__NV4h3`
};
export default ___CSS_LOADER_EXPORT___;
