import { useLayoutEffect, useRef } from 'react'
import { applyProps, useFrame } from '@react-three/fiber'
import { AccumulativeShadows, RandomizedLight, useGLTF } from '@react-three/drei'

const MercedesSClass = (props) => {
  const { scene, nodes, materials } = useGLTF('/mb.glb')

  const boxRef = useRef()

  useFrame(() => {
    if (!props.rotation) {
      boxRef.current.rotation.y = 0.7
    } else
      boxRef.current.rotation.y -= 0.001
  })

  //const { scene, nodes, materials } = useFBX('/Porsche-Panamera-GTS.fbx')
  useLayoutEffect(() => {
    Object.values(nodes).forEach((node) => node.isMesh && (node.receiveShadow = node.castShadow = true))
    applyProps(materials.Glass, { color: 'black', roughness: 0, clearcoat: 0.1 })
    applyProps(materials.Car_Paint, { envMapIntensity: 4, roughness: 0.5, metalness: 1 })
    applyProps(materials.Car_Paint_With_Flakes, {
      envMapIntensity: 2,
      roughness: 0.3,
      metalness: 1,
      color: '#85602c'
    })
    applyProps(materials.Car_chrome, { envMapIntensity: 2, roughness: 0.2, metalness: 0.8, color: '#000000' })
  }, [nodes, materials])
  return (
    <primitive ref={boxRef} object={scene} {...props} position={[0, -1.5, 0]} rotation={[0, 0, 0]}>
      <AccumulativeShadows temporal frames={100} alphaTest={0.9} scale={10}>
        <RandomizedLight amount={8} radius={10} ambient={0.5} position={[1, 5, -1]} />
      </AccumulativeShadows>
    </primitive>
  )
}


export default MercedesSClass
