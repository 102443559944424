// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://rsms.me/inter/inter.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

html,
body,
#root,
.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: black;
  color: white;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: white;
  text-decoration: none;
}

svg {
  fill: white;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;AACxB;;AAEA;;;;EAIE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,wCAAwC;AAC1C","sourcesContent":["@import url('https://rsms.me/inter/inter.css');\n\n* {\n  box-sizing: border-box;\n}\n\nhtml,\nbody,\n#root,\n.container {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  background: black;\n  color: white;\n  font-family: 'Inter';\n}\n\na {\n  color: black;\n}\n\na {\n  pointer-events: all;\n  color: white;\n  text-decoration: none;\n}\n\nsvg {\n  fill: white;\n}\n\n@keyframes fade-in {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\ncanvas {\n  opacity: 0;\n  touch-action: none;\n  animation: fade-in 5s ease 0.5s forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
